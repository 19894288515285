import { useQuery } from "react-query";

export default function useAlbums() {
  const query = useQuery(["albums"], async () => {
    return fetch(`/api/list-albums`).then((res) => res.json());
  });
  return {
    ...query,
    albums: query?.data,
  };
}

import { graphql } from "gatsby";
import GatsbyImage from "gatsby-image";
import React, { useState } from "react";
import ReactPlayer from "react-player/lazy";
import Modal from "./Modal";
import { css } from "@emotion/react";
import tw from "twin.macro";
import { MdClose } from "react-icons/md";
import Scrollbar from "./Scrollbar";
import { StaticImage } from "gatsby-plugin-image";
import cn from "classnames";
import useYTVideo from "../_api/useYTVideo";
import { FaSpinner } from "react-icons/fa";
export default function VideosList({ slice }) {
  const list_id = new URLSearchParams(slice?.primary?.list_url?.url);

  const { data, isLoading } = useYTVideo(list_id.get("list"));

  return (
    <div className="relative py-10 overflow-x-hidden lg:py-20 max-w-screen">
      <div className="container relative z-10">
        <div>
          <h1 className="mb-8 text-3xl font-bold tracking-wide text-center uppercase lg:mb-12 lg:text-4xl">
            Videos
          </h1>
        </div>

        <Scrollbar>
          <div
            className={cn("flex items-center pt-1 pb-6 space-x-6", {
              "justify-center": data?.length < 2,
            })}
          >
            {" "}
            {isLoading && (
              <span className="inline-flex items-center ">
                <FaSpinner className=" animate-spin" />
              </span>
            )}
            {data?.map(({ id, link, thumbnail }, index, items) => {
              return (
                <div
                  key={id}
                  className={cn("relative flex-shrink-0 shadow cursor-pointer")}
                >
                  <Video
                    video_link={link}
                    thumbnail_url={thumbnail}
                    total={items.length}
                  />
                </div>
              );
            })}
          </div>
        </Scrollbar>
      </div>
    </div>
  );
}

function Video({ video_link, thumbnail_url, total }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div>
        <div
          className="relative flex items-center justify-center w-full overflow-hidden text-sm rounded cursor-pointer bg-blue-light/20 group filter-shadow"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <img
            className={cn("object-contain  w-72 md:w-[300px] ", {
              "lg:w-[480px] h-72": total == 1,
              "lg:w-[320px] h-56": total > 1,
            })}
            src={thumbnail_url}
          />
          <PlayButton className="absolute z-10 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" />
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        close={() => {
          setIsOpen(false);
        }}
      >
        <div
          className="relative w-full bg-gray-900"
          css={css`
            padding-bottom: calc(9 / 16 * 100%);

            .react-player {
              transition: transform 300ms;
              width: 100% !important;
              height: 100% !important;
              transform-origin: "center center";
            }
          `}
        >
          <MdClose
            className="absolute w-10 h-10 text-white cursor-pointer -top-10 -right-10 phone:right-0"
            onClick={() => {
              setIsOpen(false);
            }}
          />
          <ReactPlayer
            className="absolute react-player"
            url={video_link}
            autoplay={true}
            config={{
              vimeo: {
                playerOptions: {
                  autoplay: true,
                  responsive: true,
                },
              },
            }}
            controls
            responsive
          />
        </div>
      </Modal>
    </>
  );
}

function PlayButton(props) {
  return (
    <svg
      width="57"
      height="57"
      viewBox="0 0 57 57"
      fill="none"
      {...props}
      css={css`
        .group:hover & {
          path {
            fill: #a2b6db;
          }
        }
      `}
    >
      <circle cx="28.5" cy="28.5" r="28.5" fill="white" />
      <path
        d="M23.7611 21.4368L36.7207 28.919L23.7611 36.4012L23.7611 21.4368Z"
        stroke="#A2B6DB"
      />
    </svg>
  );
}

export const query = graphql`
  fragment Youtube on PrismicHomepageBodyYoutube {
    items {
      youtube_video {
        embed_url
        thumbnail_url
      }
    }
  }
`;

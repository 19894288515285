import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import EventsV3 from "../components/EventsV3";
import Locations from "../components/LocationsV2";
import AlbumsV2 from "../components/AlbumsV2";
import Videos from "../components/Videos";
import CTA from "../components/CTA";
import OnlineShop from "../components/OnlineShop";
import Seo from "../components/SEO";
import VideosList from "../components/VideosList";
function IndexPage({ data }) {
  return (
    <Layout>
      <Seo data={data?.prismicHomepage?.seo} />

      {data?.prismicHomepage?.data?.body?.map((slice, index) => {
        switch (slice.slice_type) {
          case "locations":
            return <Locations key={index} data={slice} />;
          case "youtube":
            return <Videos key={index} data={slice} />;
          case "events":
            return <EventsV3 key={index} />;
          case "albums":
            return <AlbumsV2 key={index} />;
          case "call_to_action":
            return <CTA data={slice} key={index} />;
          case "online_shop":
            return <OnlineShop data={slice} key={index} />;
          case "online_shop":
            return <OnlineShop data={slice} key={index} />;
          case "youtube_list":
            return <VideosList slice={slice} key={index} />;

          default:
            return slice.slice_type;
        }
      })}
    </Layout>
  );
}

export default IndexPage;

export const query = graphql`
  {
    prismicHomepage {
      seo: data {
        meta_title
        meta_description
        meta_image {
          url
        }
      }
      data {
        body {
          ... on PrismicHomepageBodyLocations {
            id
            slice_type
            ...BodyLocations
          }
          ... on PrismicHomepageBodyYoutube {
            ...Youtube
            slice_type
          }
          ... on PrismicHomepageBodyYoutube {
            ...Youtube
            slice_type
          }
          ... on PrismicHomepageBodyEvents {
            slice_type
          }
          ... on PrismicHomepageBodyAlbums {
            slice_type
          }
          ... on PrismicHomepageBodyCallToAction {
            ...HomepageCallToAction
            slice_type
          }
          ... on PrismicHomepageBodyOnlineShop {
            ...HomepageOnlineShop
            slice_type
          }
          ... on PrismicHomepageBodyYoutubeList {
            slice_type
            primary {
              list_url {
                url
              }
            }
          }
        }
      }
    }
  }
`;

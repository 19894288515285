import React from "react";
import { Link } from "gatsby";

import { css } from "@emotion/react";
import { StaticImage } from "gatsby-plugin-image";
import tw from "twin.macro";
import { FaSpinner } from "react-icons/fa";
import Scrollbar from "./Scrollbar";
import useAlbums from "../_api/useAlbums";
export default function AlbumsV2() {
  const { albums, ...query } = useAlbums();
  return (
    <div className="relative py-10 overflow-x-hidden lg:py-20 max-w-screen">
      <div className="container relative z-10">
        <div>
          <h1 className="mb-8 text-3xl font-bold tracking-wide text-center uppercase lg:mb-12 lg:text-4xl">
            Alben
          </h1>
        </div>

        <Scrollbar>
          <div className="flex items-center pb-6 space-x-6 ">
            {query.isLoading &&
              Array.from({ length: 4 }).map((item, index) => {
                return (
                  <div
                    key={index}
                    className="h-64 bg-blue-light/50 animate-pulse w-72 md:w-[300px] lg:w-[400px]"
                  ></div>
                );
              })}{" "}
            {albums?.map((album) => {
              return (
                <Link
                  to={`/album/${album.id}`}
                  key={album.id}
                  className="flex-shrink-0 relative cursor-pointer w-72 md:w-[300px] lg:w-[320px] shadow"
                >
                  <img
                    src={album?.cover_photo?.images?.[3]?.source}
                    className="object-cover w-full h-56 lg:h-64"
                  />
                  <div className="absolute inset-0 grid transition-opacity duration-100 opacity-0 hover:opacity-100 group bg-blue-light bg-opacity-90 place-items-center">
                    <div className="p-4 font-semibold text-center text-black">
                      <h3 className="text-2xl ">{album?.name}</h3>
                    </div>
                  </div>
                </Link>
              );
            })}
            {query.hasNextPage && (
              <div
                onClick={() => {
                  query.fetchNextPage();
                }}
                className="cursor-pointer self-stretch flex-shrink-0 font-bold dark:text-white/80 dark:bg-blue-dark/80 w-72 md:w-[300px] lg:w-[400px] bg-white  text-black flex items-center justify-center  "
              >
                Mehr Laden{" "}
                {query.isFetching && (
                  <FaSpinner className="ml-3 animate-spin" />
                )}
              </div>
            )}
          </div>
        </Scrollbar>
      </div>
    </div>
  );
}

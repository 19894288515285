import React from "react";
import { useInfiniteQuery } from "react-query";

export default function usePagination({ key, endpoint, param }) {
  function getData({ pageParam }) {
    return fetch(
      `/api/${endpoint}?${param ? "id=" + param : ""}${
        pageParam ? `&after=${pageParam}` : ""
      }`
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });
  }

  const query = useInfiniteQuery([key, param], getData, {
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.hasNext && lastPage?.cursors?.after;
    },
  });

  let data = [];
  query?.data?.pages?.forEach((page) => {
    page?.data && data?.push(...page?.data);
  });

  return {
    ...query,
    [key]: data,
  };
}

import { useQuery } from "react-query";

export default function useYTVideo(id) {
  const query = useQuery(
    ["ytVideos", id],
    async () => {
      return fetch(`/api/get-yt-videos?id=${id}`).then((res) => res.json());
    },
    {
      enabled: Boolean(id),
      select: (data) =>
        data.map((item) => {
          const id = item.snippet.resourceId.videoId;
          return {
            id,
            link: `https://www.youtube.com/embed/${id}`,
            thumbnail:
              item.snippet.thumbnails?.medium?.url ||
              item.snippet.thumbnails?.default?.url,
          };
        }),
    }
  );
  return query;
}
